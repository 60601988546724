$medium-up: 'only screen and (min-width: 40.063em)' !default;

$ROOT: '.MediaBlock';

#{$ROOT} {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-outerMedia {
    display: none;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &-content {
    width: 100%;
  }

  @media #{$medium-up} {
    flex-direction: row;
    align-items: unset;

    &--reverseRow {
      flex-direction: row-reverse;
    }
    &--rowMediaCentered {
      align-items: center;
    }
    &-outerMedia {
      display: block;
    }
    &-innerMedia {
      display: none;
    }
    &--alwaysColumn {
      flex-direction: column;

      #{$ROOT}-outerMedia {
        display: none;
      }
      #{$ROOT}-innerMedia {
        display: block;
      }
    }
  }
}
